import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { selector } from "containers/BaseContainer";

import BaseView from "views/BaseView";
import { connect } from "react-redux";
import RequestAction from "../../actions/RequestAction";
import { Card, Button, Grid, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import FilterFrom from "./components/FilterFrom";
import { Form, Validation } from "components/Forms";
import ExportExcel from "components/ExportExcel/ExportExcel";
// import ReactToPrint from "react-to-print";
import moment from "moment";
import PDFComponent from "../MonthlyReportExport/Index";
import MonthlyPDF from "../MonthlyPDF/Index";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import ConfirmDialogNotify from "components/Dialogs/ConfirmDialog";
import Modal from "../../components/Dialogs/Modal_Simple";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import AutoCompleteField, {
  Option as OptionAuto,
} from "components/Forms/AutoCompleteField";
import "./style.css";
moment.locale("ja");
var date_current = [];
var yearMonth = 0;
var startDate = 0;
var endDate = 0;
const GridTable = React.lazy(() => import("components/Table/GridTable"));
const styles = (theme) => ({
  gridTable: {
    height: '"calc(100vh + 20px)"',
  },
});

class Index extends BaseView {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      open: false,
      dataEdit: {},
      reload: false,
      isFomat60: true,
      isRounding: false,
    };
    this.showModal = (e) => {
      this.setState({
        show: !this.state.show,
      });
    };
    this.SubMit = (e) => {
      this.setState({
        show: !this.state.show,
      });
      const { data, getApprover } = this.props;

      getApprover(() => {
        this.ConfirmDialog.show({ yearMonth });
      });
    };

    this.validate = {
      memo: [Validation.maxLength(20, I18n.t("Form.mangleng20"))],
      manage1: [Validation.required(I18n.t("Form.required"))],
      manage2: [Validation.required(I18n.t("Form.required"))],
    };
    this.onSubmitRequest = this.onSubmitRequest.bind(this);
    this.table = {
      columns: [
        {
          name: "dateText",
          filterable: false,
          title: I18n.t("Input.dateText"),
          type: "text",
        },
        {
          name: "date",
          filterable: false,
          title: I18n.t("Input.date"),
          type: "text",
          formatterComponent: (data, index) => {
            if (data.row.totalRow) return <span></span>;
            return (
              <span key={data.value}>{moment(data.value).format("dd")}</span>
            );
          },
        },
        {
          name: "constructions.code",
          title: I18n.t("Table.header.construction.code"),
          filterable: false,
          sortable: false,
          excelArray: {
            field: "constructions",
            childField: "code",
            pageDownload: "monthReport",
          },
          formatterComponent: (data) => {
            if (data.row.totalRow) {
              return <span>{I18n.t("Table.header.labor.laborTotal")} </span>;
            }
            let constructions = this.customColumn(
              data,
              "row.constructions",
              []
            );
            return constructions.map((con, index) => {
              if (con) {
                return (
                  <p key={index}>
                    {con.code.replace(/-/g, "")} {con.name}
                  </p>
                );
              }
            });
          },
        },
        {
          name: "totalWorkingConstructions",
          title: I18n.t("Table.header.construction.workTimeReport"),
          filterable: false,
          sortable: false,
          excelArray: {
            field: "constructions",
            childField: "workTime",
          },
          formatterComponent: (data) => {
            if (data.row.totalRow) {
              return <span>{data.row.totalWorkingConstructions}</span>;
            }
            let constructions = this.customColumn(
              data,
              "row.constructions",
              []
            );
            return constructions.map((con, index) => {
              if (con) {
                return <p key={index}>{con.workTime}</p>;
              }
            });
          },
        },
        {
          name: "workHourRange",
          title: I18n.t("Table.header.monthlyReport.workHourRange"),
          type: "text",
          formatterComponent: (data, index) => {
            if (data.row._startTime) {
              return <span key={index}>{data.row.workHourRange}</span>;
            }
            return <span key={index}></span>;
          },
          filterable: false,
          sortable: false,
        },
        {
          name: "totalWorkHour",
          title: I18n.t("Table.header.construction.totalWorkingTime"),
          filterable: false,
          sortable: false,
          type: "text",
          formatterComponent: (data, index) => {
            if (data.row.totalRow) {
              return <span>{data.row.totalWorkHour}</span>;
            }
            if (data.row.work_hour !== undefined) {
              return <span key={index}>{data.row.work_hour} </span>;
            } else {
              return <span key={index}></span>;
            }
          },
        },
        {
          name: "typeOfWorkingDay",
          title: I18n.t("Table.header.monthlyReport.dateType"),
          filterable: false,
          sortable: false,
          formatterComponent: (data) => {
            let typeOfWorkingDay = this.customColumn(
              data,
              "row.typeOfWorkingDay",
              []
            );

            if (Array.isArray(typeOfWorkingDay)) {
              if (typeOfWorkingDay && typeOfWorkingDay.length > 0) {
                return typeOfWorkingDay.map((item, index) => {
                  if (item) {
                    return <p key={index}>{item}</p>;
                  }
                });
              }
              return <p></p>;
            } else {
              if (typeOfWorkingDay) return typeOfWorkingDay;
              return <p></p>;
            }
          },
        },
        {
          name: "alcohol",
          title: I18n.t("Table.header.alcohol_count"),
          filterable: false,
          sortable: false,
          formatterComponent: (data) =>
            data.row?.alcohol ? data.row.alcohol : null,
        },
        {
          name: "intoxicated_mouth_less",
          title: I18n.t("Table.header.intoxicated_mouth_less"),
          filterable: false,
          sortable: false,
          formatterComponent: (data) =>
            data.row?.intoxicated_mouth === "medium" ? "✅" : null,
        },
        {
          name: "intoxicated_mouth_more",
          title: I18n.t("Table.header.intoxicated_mouth_more"),
          filterable: false,
          sortable: false,
          formatterComponent: (data) =>
            data.row?.intoxicated_mouth === "high" ? "✅" : null,
        },
        {
          name: "driving_length_on_working",
          title: I18n.t("Table.header.drivingLengthOnWorking"),
          filterable: false,
          sortable: false,
          formatterComponent: (data) => {
            if (data.row.driving_length_on_working) {
              return (
                <React.Fragment>
                  {data.row.driving_length_on_working.toLocaleString()}
                </React.Fragment>
              );
            }
            return <React.Fragment></React.Fragment>;
          },
        },
        {
          name: "driving_length_on_private",
          title: I18n.t("Table.header.drivingLengthOnPrivate"),
          filterable: false,
          sortable: false,
          formatterComponent: (data) => {
            if (data.row.driving_length_on_private) {
              return (
                <React.Fragment>
                  {data.row.driving_length_on_private.toLocaleString()}
                </React.Fragment>
              );
            }
            return <React.Fragment></React.Fragment>;
          },
        },
        {
          name: "fuel_supply_amount",
          title: I18n.t("Table.header.fuelSupplyAmount"),
          filterable: false,
          sortable: false,
          formatterComponent: (data) => {
            if (data.row.fuel_supply_amount) {
              return (
                <React.Fragment>
                  {data.row.fuel_supply_amount.toLocaleString()}
                </React.Fragment>
              );
            }
            return <React.Fragment></React.Fragment>;
          },
        },
        {
          name: "normalOT",
          title: I18n.t("Table.header.OT"),
          filterable: false,
          sortable: false,
        },
        {
          // name: "nightOT",
          // title: I18n.t("Table.header.overNightWorking"),
          name: "workNightHour",
          title: I18n.t("Table.header.nightTime"),
          filterable: false,
          sortable: false,
        },
        {
          name: "typeRequestWorkingDay",
          title: I18n.t("Table.header.monthlyReport.typeRequestWorkingDay"),
          filterable: false,
          sortable: false,
          formatterComponent: (data) => {
            let typeRequestWorkingDay = this.customColumn(
              data,
              "row.typeRequestWorkingDay",
              []
            );

            return typeRequestWorkingDay.map((item, index) => {
              if (item) {
                return <p key={index}>{item}</p>;
              }
            });
          },
        },
        {
          name: "memo",
          title: I18n.t("Table.header.memo"),
          filterable: false,
          sortable: false,
          formatterComponent: (data) => {
            return (
              <React.Fragment>{data.row.memo && data.row.memo}</React.Fragment>
            );
          },
        },
        {
          name: "memo_calendar",
          title: I18n.t("Table.header.memo_calendar"),
          filterable: false,
          sortable: false,
          hidden: true,
        },
        {
          name: "memo_request",
          title: I18n.t("Table.header.memo_request"),
          filterable: false,
          sortable: false,
          hidden: true,
        },
      ],
      defaultSort: [{ columnName: "date", direction: "desc" }],
      //tên các column name không muốn xuất file
      ignoreExcelColumns: ["index", "_id", "memo"],
      ignorePrintColumns: ["_id", "memo_request", "memo_calendar"],
      tableColumnExtensions: [
        { columnName: "index", align: "center", wordWrapEnabled: true },
        { columnName: "constructions.code", wordWrapEnabled: true },
        { columnName: "totalWorkingConstructions", wordWrapEnabled: true },
        { columnName: "workHourRange", wordWrapEnabled: true },
        { columnName: "totalWorkHour", wordWrapEnabled: true },
        { columnName: "typeOfWorkingDay", wordWrapEnabled: true },
        { columnName: "dateType", wordWrapEnabled: true },
        { columnName: "driving_length_on_working", wordWrapEnabled: true },
        { columnName: "driving_length_on_private", wordWrapEnabled: true },
        { columnName: "fuel_supply_amount", wordWrapEnabled: true },
        { columnName: "normalOT", wordWrapEnabled: true },
        { columnName: "workNightHour", wordWrapEnabled: true },
        { columnName: "typeRequestWorkingDay", wordWrapEnabled: true },
        { columnName: "memo", wordWrapEnabled: true },
        { columnName: "alcohol", wordWrapEnabled: true },
        { columnName: "intoxicated_mouth_less", wordWrapEnabled: true },
        { columnName: "intoxicated_mouth_more", wordWrapEnabled: true },
      ],
      //set độ rộng của excel và của bảng gridTable
      columnWidths: [
        {
          name: "dateText",
          width: 47,
        },
        {
          name: "date",
          width: 64,
        },
        {
          name: "constructions.code",
          width: 200,
        },
        {
          name: "totalWorkingConstructions",
          width: 90,
        },
        {
          name: "workHourRange",
          width: 70,
        },
        {
          name: "totalWorkHour",
          width: 75,
        },
        {
          name: "typeOfWorkingDay",
          width: 70,
        },
        {
          name: "alcohol",
          width: 100,
        },
        {
          name: "intoxicated_mouth_less",
          width: 105,
        },
        {
          name: "intoxicated_mouth_more",
          width: 90,
        },
        {
          name: "driving_length_on_working",
          width: 78,
        },
        {
          name: "driving_length_on_private",
          width: 75,
        },
        {
          name: "fuel_supply_amount",
          width: 80,
        },
        {
          name: "normalOT",
          width: 80,
        },
        {
          // name: "nightOT",
          name: "workNightHour",
          width: 80,
        },
        {
          name: "typeRequestWorkingDay",
          width: 80,
        },
        {
          name: "memo",
          width: 120,
        },
        {
          name: "memo_calendar",
          width: 140,
        },
        {
          name: "memo_request",
          width: 50,
        },
      ],
    };
    this.onCancel = this.onCancel.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.ConfirmDialog = null;
    this.ConfirmDialogNotify = null;
    this.renderToolbarActions = this.renderToolbarActions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(e) {
    let stickyHeader = document.getElementById("check-sticky-header");
    let stickyRect = stickyHeader.getBoundingClientRect();
    let topDistance = stickyRect.height + stickyRect.top;
    let tableToolbar = document.getElementById("table-grid-toolbar");
    let tableRender = document.getElementById("boldLastRow");
    let tableSticky = [].slice
      .call(
        document
          .getElementById("table-grid-root")
          .getElementsByTagName("table"),
        0
      )
      .reverse()[1];
    let tableRect = tableRender.getBoundingClientRect();

    if (tableRect.top - topDistance <= 0) {
      tableToolbar.setAttribute(
        "style",
        this.buildStyleAttribute(
          tableRect.width,
          topDistance + 1,
          tableRender.offsetLeft
        )
      );
      tableSticky.setAttribute(
        "style",
        this.buildStyleAttribute(
          tableRect.width,
          topDistance + 1 + tableToolbar.offsetHeight,
          tableRender.offsetLeft
        )
      );
    } else {
      tableToolbar.removeAttribute("style");
      tableSticky.removeAttribute("style");
    }

    if (window.scrollY > tableRender.offsetHeight || window.scrollY === 0) {
      tableToolbar.removeAttribute("style");
      tableSticky.removeAttribute("style");
    }
  }

  buildStyleAttribute(width, top, left) {
    const baseStyle = "position:fixed;background-color:#fff;z-index:9;";

    return `${baseStyle}width:${width}px;top:${top}px;left:${left}px`;
  }

  isFormat60 = (bool) => {
    this.setState({
      isFomat60: bool,
    });
  };

  customHourColumn(data, path) {
    // let defaultValue =  this.props.is60Time? "00.00": '0.00'
    let totalRow = this.getData(data, "row.totalRow", false);
    if (totalRow) {
      data = this.getData(data, path, "");
    } else {
      data = this.getData(data, path, "0");
    }
    return String(data);
  }

  customColumn(data, path, defaultValue = "") {
    data = this.getData(data, path, defaultValue);
    return data;
  }

  onCancel() {
    this.onHide();
  }

  onShow(dataEdit) {
    this.setState({ open: true, dataEdit: dataEdit });
    this.setState({ reload: !this.state.reload });
  }

  onHide() {
    this.setState({ open: false });
  }

  getHeaderText() {
    let print_timekeepings =
      JSON.parse(localStorage.getItem("print_timekeepings")) || {};
    return `${I18n.t("Input.month")}: ${print_timekeepings.month}, ${I18n.t(
      "Table.header.user.code"
    )}:  ${print_timekeepings.code}, ${I18n.t("Table.header.user.name")}: ${
      print_timekeepings.name
    }`;
  }

  getExcelName = () => {
    let print_timekeepings =
      JSON.parse(localStorage.getItem("print_timekeepings")) || {};
    return `${print_timekeepings.code}_${print_timekeepings.name}`;
  };

  renderToolbarActions() {
    let { data = [] } = this.props.data;
    let { user } = this.props.data;
    if (!user) return null;

    //tô đậm dòng tổng;
    if (data.length) {
      data[data.length - 1].style = { fontWeight: "bold" };
    }
    //thêm trường tổng vào
    if (data.length) {
      data[data.length - 1].absent = I18n.t("Table.header.labor.laborTotal");
    }
    let { ignoreExcelColumns, columnWidths, columns, ignorePrintColumns } =
      this.table;
    const headerText = this.getHeaderText();
    const userInfo = [[user.name, `社員コード：${user.code}`]];

    data = data.map((item) => {
      if (item && item._startTime !== undefined) {
        item.workHourRange = `${moment(new Date(item._startTime)).format(
          "HH:mm"
        )} - `;
        if (
          item.endTime &&
          item.endTime !== undefined &&
          item._endTime &&
          item._endTime !== undefined
        ) {
          item.workHourRange += `${moment(new Date(item._endTime)).format(
            "HH:mm"
          )}`;
        }
      }
      return item;
    });

    return [
      <MonthlyPDF data={this.props.data} />,
      // <ReactToPrint
      //   removeAfterPrint={true}
      //   trigger={() => (
      //     <Tooltip title={I18n.t("toolTip.print")} key="print">
      //       <IconButton>
      //         <Icon>local_printshop</Icon>
      //       </IconButton>
      //     </Tooltip>
      //   )}
      //   content={() => this.printArea}
      // />,
      <div style={{ display: "none" }}>
        <div ref={(ref) => (this.printArea = ref)}>
          <PDFComponent
            data={this.props.data}
            printMode={true}
            exportType="print"
            autoDownload={false}
            classes={{}}
          />
        </div>
      </div>,
      // <Tooltip title={I18n.t("toolTip.pdf")} key="print">
      //   <IconButton onClick={() => this.PDFComponent.createPdf()}>
      //     <Icon>picture_as_pdf</Icon>
      //   </IconButton>
      // </Tooltip>,
      <ExportExcel
        ignoreExcelColumns={ignoreExcelColumns}
        // unwind={{ field: "constructions", groupBy: "date", nonMergeField: ['constructions.code', 'constructions.name'] }}
        name={this.getExcelName}
        userInfo={userInfo}
        data={data}
        columnWidths={columnWidths}
        columns={columns}
        key="exportExcel"
        type="csv"
      />,
    ];
  }

  onSubmitRequest(value) {
    let { data, submitRequest } = this.props;
    yearMonth = moment(new Date(data.queryDate)).format("YYYY-MM");
    submitRequest(
      {
        ...value,
        yearMonth,
      },
      () => this.ConfirmDialog.hide()
    );
  }

  onRequestMonthReport = () => {
    this.onShowApprovingModal();
  };

  renderDialogNotifyError() {
    return (
      <ConfirmDialogNotify
        ref={(ref) => (this.ConfirmDialogNotify = ref)}
        content={I18n.t("Message.notifyErrorTotal")}
        onSubmit={this.onRequestMonthReport}
      />
    );
  }

  renderDialogConfirmDelete = () => {
    let { approver, data } = this.props;
    let request = data.request || {};
    const queryDate = moment(data.queryDate).format("MM");

    return (
      <ConfirmDialog
        ref={(ref) => (this.ConfirmDialog = ref)}
        title={
          <span>
            月報申請
            <br />
            申請先を指定してください
          </span>
        }
        content={
          <div style={{ height: "400px", width: "400px" }}>
            <Form
              ref={(ref) => (this.form = ref)}
              onSubmit={this.onSubmitRequest}
            >
              <Grid item xs={12} lg={12}>
                <AutoCompleteField
                  key="1"
                  fullWidth
                  select
                  label={I18n.t("Input.newManage1")}
                  name="manage1"
                  isMulti={false}
                  value={request.manage1}
                  validate={this.validate.manage1}
                >
                  {approver.user_level1.map((item) => (
                    <OptionAuto
                      key={item._id}
                      value={item._id}
                      showCheckbox={false}
                    >
                      {item.name}
                    </OptionAuto>
                  ))}
                </AutoCompleteField>
              </Grid>
              <Grid item xs={12} lg={12}>
                <AutoCompleteField
                  key="2"
                  fullWidth
                  select
                  label={I18n.t("Input.newManage2")}
                  name="manage2"
                  isMulti={false}
                  value={request.manage2}
                  validate={this.validate.manage2}
                >
                  {approver.user_level2.map((item) => (
                    <OptionAuto
                      key={item._id}
                      value={item._id}
                      showCheckbox={false}
                    >
                      {item.name}
                    </OptionAuto>
                  ))}
                </AutoCompleteField>
              </Grid>
              <Grid item xs={12} lg={12}>
                <p style={{ color: "red", fontWeight: "bold" }}>
                  {queryDate}月の月報を申請しますが、よろしいでしょうか？
                </p>
              </Grid>
              <button
                type="submit"
                ref={(button) => (this.submitButton = button)}
                style={{ display: "none" }}
              ></button>
            </Form>
          </div>
        }
        textCancel={I18n.t("Button.cancel")}
        textSubmit={I18n.t("Button.request")}
        onSubmit={this.onClickSubmitRequest}
        manualHide={true}
      />
    );
  };

  onClickSubmitRequest = () => {
    this.submitButton.click();
  };

  onShowApprovingModal = () => {
    const { data, getApprover } = this.props;
    var input_date =
      document.getElementById("select-selectField").firstChild.data;
    let date_replace = input_date.replace(".", "-");
    const { queryDate } = data;
    var endDate = 0;
    yearMonth = moment(new Date(date_replace)).format("YYYY-MM");
    endDate = moment(new Date(date_replace))
      .endOf("month")
      .format("YYYY-MM-DD");
    startDate = moment(date_replace).startOf("month").format("YYYY-MM-DD");
    const that = this;
    date_current = [];
    let user = JSON.parse(localStorage.getItem("user"))._id;
    this.props
      .dispatch(
        RequestAction.fetchAll({
          // calendars: 'calendars'
          flag: "monthly_reports",
          from: startDate,
          to: endDate,
        })
      )
      .then((response) => {
        let days = JSON.parse(JSON.stringify(response.data.data));
        for (let i of days) {
          if (
            i.date > startDate &&
            i.date < endDate &&
            i.userId === user &&
            i.status != true
          ) {
            date_current.push(i.date);
          }
        }
        if (date_current.length > 0) {
          this.showModal();
        } else {
          getApprover(() => {
            this.ConfirmDialog.show({ yearMonth });
          });
        }
      });
  };

  render() {
    let {
      data = {},
      classes,
      users = [],
      onSubmit,
      _isRound,
      isRound,
      approver,
    } = this.props;
    let rows = data.data || [];
    let construction = _.get(data, "userConstructions", {});
    let height = "auto";

    let dataCheckNotify = undefined;
    if (
      this.props.data &&
      this.props.data.data &&
      this.props.data.data.length > 0
    ) {
      this.props.data.data.map((item) => {
        if (item.dateType === "total") {
          dataCheckNotify = item;
        }
      });
    }

    return (
      <React.Fragment>
        <Modal
          onClose={this.showModal}
          show={this.state.show}
          onSubmit={this.SubMit}
        />
        {approver.user_level1 && this.renderDialogConfirmDelete()}
        {this.renderDialogNotifyError()}
        <PDFComponent
          classes={{}}
          data={data}
          exportType="pdf"
          ref={(ref) => (this.PDFComponent = ref)}
        />
        <div className="monthly-report-head" id="check-sticky-header">
          <FilterFrom
            isFormat60={this.isFormat60}
            isRound={isRound}
            _isRound={_isRound}
            users={users}
            onSubmit={onSubmit}
          />
          <Grid
            container
            spacing={16}
            style={{ padding: "10px 32px 15px 32px" }}
            justify="space-between"
          >
            <Grid item>
              {data.user && (
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ fontSize: "0.9rem" }}
                >
                  <span>車番 : {data.user.car_number}</span>
                </Typography>
              )}
            </Grid>
            <Grid item>
              {data.user && (
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ fontSize: "0.9rem" }}
                >
                  <span>社員コード : {data.user.code}</span>
                </Typography>
              )}
            </Grid>
            <Grid item>
              {data.user && (
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ fontSize: "0.9rem" }}
                >
                  <span>ユーザーID : {data.user.name}</span>
                </Typography>
              )}
            </Grid>
            <Grid item>
              {data.requestApproved && (
                <Button
                  variant="contained"
                  disabled
                  className={classes.fixButton}
                >
                  承認済
                </Button>
              )}
              {data.showSubmitButton && (
                <Button
                  // onClick={this.onShowApprovingModal}
                  onClick={
                    dataCheckNotify !== undefined &&
                    dataCheckNotify.totalWorkHour !==
                      dataCheckNotify.totalWorkingConstructions
                      ? () => this.ConfirmDialogNotify.show()
                      : this.onShowApprovingModal
                  }
                  variant="contained"
                  color="primary"
                  className={classes.fixButton}
                >
                  申請
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
        <Card showloading="true">
          <div id="boldLastRow">
            <GridTable
              ref={(ref) => {
                this.gridTable = ref;
              }}
              id="MonthlyReportIndex1"
              className={classes.gridTable}
              onFetchData={this.props.onFetchData}
              onRefTable={this.props.onRefTable}
              columns={this.table.columns}
              defaultSort={this.table.defaultSort}
              height={height}
              tableActions={this.renderToolbarActions}
              defaultColumnWidths={this.table.columnWidths}
              tableColumnExtensions={this.table.tableColumnExtensions}
              rows={[...(rows || [])]}
              filterRowHiding={true}
              pageHiding={true}
              hiddenColumnMonth={true}
            />
          </div>
        </Card>

        <Card style={{ marginTop: 20, marginBottom: 30 }}>
          <Grid container spacing={8}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell>出勤日数</TableCell>
                  <TableCell style={{ width: 100 }}>
                    {data.total_working_day}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>休日出勤日数</TableCell>
                  <TableCell>
                    {data.requests &&
                      Object.keys(data.requests[0].workOnDayOffReq).length}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>先月最終メーター</TableCell>
                  <TableCell>
                    {(data.drivingLog && data.drivingLog.prevMonth.length) || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>今月最終メーター</TableCell>
                  <TableCell>
                    {(data.drivingLog && data.drivingLog.currentMonth.length) ||
                      0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>期間走行距離</TableCell>
                  <TableCell>
                    {data.period_mileage &&
                      data.period_mileage.toLocaleString()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>通勤＋業務距離</TableCell>
                  <TableCell>
                    {data.driving_length_on_working &&
                      data.driving_length_on_working.toLocaleString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Card>

        {construction.list && construction.list.length > 0 && (
          <Card style={{ marginBottom: 30 }}>
            <Grid container spacing={8}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell>工事</TableCell>
                    <TableCell style={{ width: 100 }}>時間</TableCell>
                  </TableRow>
                  {construction.list.map((con) => (
                    <TableRow key={con.constructionId}>
                      <TableCell>{`${con.code.replace(/-/g, "")} ${
                        con.name
                      }`}</TableCell>
                      <TableCell>{con.workTime}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>合計</TableCell>
                    <TableCell>{construction.total}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Card>
        )}
      </React.Fragment>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    data: selector(state, "monthlyReport.list", []),
    users: selector(state, "monthlyReport.list.users", []),
    approver: selector(state, "request.getApprover", {}),
  };
};

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Index)));
