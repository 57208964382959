import React, { Component } from "react"
import XLSX from 'xlsx';
import _ from 'lodash'
import { IconButton, Icon, Tooltip } from '@material-ui/core/';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { I18n } from 'react-redux-i18n'

export default class ExportExcel extends Component {
    constructor(props) {
        super(props)
        this.export = this.export.bind(this)
        this.state = {
            anchorEl: null
        }
    }
    //lấy độ rộng
    getWidths(columns, columnWidths) {
        let wscols = []
        columns.map(column => {
            columnWidths.map(columnWidth => {
                if (column.name === columnWidth.name) {
                    wscols.push({ wpx: columnWidth.width || 100 })
                }
            })
        })
        return wscols
    }
    getValue(row, column) {
        const { name, excelArray, type, isConstructionHour } = column
        //nếu trường tổng thì in mặc định không format
        if (row?.totalRow) {
            return String(_.get(row, name, ""))
        }
        let value = ""
        if (excelArray) {
            let { field, childField, pageDownload } = excelArray
            let array = _.get(row, field, [])
            let length = array.length
            array.map((element, index) => {
                if(element[childField] !== undefined) {
                    if (pageDownload && pageDownload !== undefined &&  childField == 'code') {
                        value += element[childField] + " " + element["name"]
                    } else {
                        value += element[childField]
                    }
                    
                    if (index !== length - 1) {
                        //xuống dòng
                        value += '\r\n'
                    }
                } 
            })
        } else {
            value = _.get(row, name, "") || ""
            if (type === 'number' && !value) value = '0'
        }
        
        if (name == "memo_request") {
            value = (_.get(row, name, "") || "")?.replaceAll("\n", "")
        }

        switch (name) {
          case "alcohol":
            if (isConstructionHour) {
              value = _.get(row, "totalAlcoholTests", "") || null;
            }
            break;
            
          case "intoxicated_mouth_less":
            value = isConstructionHour 
              ? _.get(row, "mediumAlcoholCount", "") || null 
              : (_.get(row, "intoxicated_mouth", "") === "medium" ? "✅" : null);
            break;
            
          case "intoxicated_mouth_more":
            value = isConstructionHour 
              ? _.get(row, "highAlcoholCount", "") || null 
              : (_.get(row, "intoxicated_mouth", "") === "high" ? "✅" : null);
            break;
          case "totalWorkHour":
            const totalRow = _.get(row, "totalRow", "") 
            value = totalRow ? totalRow : _.get(row, "work_hour", "")
            break;
            
          default:
            break;
        }

        return value
    }
    export(type, customData = null) {
        let { data = [], columns = [],
            columnWidths = [], ignoreExcelColumns = [],
            // unwind = false,
            formula } = JSON.parse(JSON.stringify(this.props))
        if (customData) {
            data = customData
        }
        let { name = "sheet" } = this.props
        if (!columns.length) {
            return false
        }
        if (typeof name === 'function') {
            name = this.props.name()
        }
        // lấy những cột muốn xuất file  
        columns = columns.filter((column, index) => {
            return !ignoreExcelColumns.includes(column.name)
        })

        let merge = []
        // //unwind nếu là mảng để in ra gộp ô
        // let format_rows = []
        ////code merge cell
        // if (unwind) {
        //     const { field } = unwind
        //     for (let index in data) {
        //         let row = data[index]
        //         //lấy phần tử muốn unwind
        //         let unwindField = row[field]
        //         //xóa trường đó đi để format lại
        //         delete row[field]
        //         let unwindRows = []
        //         if (Array.isArray(unwindField)) {
        //             unwindRows = unwindField.map(item => {
        //                 row[field] = item
        //                 return JSON.parse(JSON.stringify(row))
        //             })
        //         }
        //         format_rows = format_rows.concat(unwindRows)
        //     }
        //     let mergeObj = {}
        //     //tạo các hàng muốn gộp
        //     format_rows.map((row, index) => {
        //         let groupBy = row[unwind.groupBy]
        //         if (mergeObj[groupBy]) {
        //             mergeObj[groupBy].push(index)
        //         } else {
        //             mergeObj[groupBy] = []
        //             mergeObj[groupBy].push(index)
        //         }
        //     })
        //     Object.keys(mergeObj).map(key => {
        //         if (mergeObj[key].length > 1) {
        //             const length = mergeObj[key].length
        //             let start = mergeObj[key][0] //phần tử đầu
        //             let end = mergeObj[key][length - 1] //phần tử cuối
        //             columns.map((column, index) => {
        //                 if (!unwind.nonMergeField.includes(column.name)) {
        //                     merge.push({
        //                         s: { r: start + 1, c: index }, e: { r: end + 1, c: index }
        //                     })
        //                 }
        //             })
        //         }
        //     })
        // } else {
        //     format_rows = data
        // }

        // data = format_rows
        //tạo header
        let header = columns.map((data, index) => {
            return data.title
        })

        //tạo data
        let rows = data.map((item, i) => {
            let row = []
            row = columns.map(column => {
                return this.getValue(item, column)
            })
            return row
        })
        //lấy độ rộng
        let wscols = this.getWidths(columns, columnWidths)

        rows.unshift(header)
        const ws = XLSX.utils.aoa_to_sheet(rows);

        ws['!cols'] = wscols;
        ws['!merges'] = merge;
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        if (formula) {
            for (let i = 2; i <= data.length + 1; i++) {
                let range = 'H' + i
                let _formula = "A" + i + "/G" + i + "*F" + i
                XLSX.utils.sheet_set_array_formula(ws, range, _formula)
            }
        }

        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, name + "." + type, { type: "array", bookType: type })
    }

    exportMultiple(type, userInfos, datas) {
        let { columns = [], columnWidths = [],
             ignoreExcelColumns = [],
            //   unwind = false, formula 
            } = JSON.parse(JSON.stringify(this.props))
        let { name = "sheet" } = this.props
        if (!columns.length) {
            return false
        }
        if (typeof name === 'function') {
            name = this.props.name()
        }
        columns = columns.filter((column, index) => {
            return !ignoreExcelColumns.includes(column.name)
        })

        let merge = []
        let header = columns.map((data, index) => {
            return data.title
        })

        let rows = []
        datas.forEach((data, i) => {
            rows.push(userInfos[i][0])
            rows.push(header)
            data.map((item, yy) => {

                let row = []
                row = columns.map(column => {
                    return this.getValue(item, column)
                })
                rows.push(row)
            })
        })
        let wscols = this.getWidths(columns, columnWidths)

        const ws = XLSX.utils.aoa_to_sheet(rows);

        ws['!cols'] = wscols;
        ws['!merges'] = merge;
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, name + "." + type, { type: "array", bookType: type })
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }
    exportCSV = () => {
        this.export('csv')
        this.handleClose();
    }
    exportXlsx = () => {
        this.export('xlsx')
        this.handleClose();
    }

    exportMultileXlsx = (type='xlsx') => {
        this.props.onClick(type='xlsx', (extension='xlsx', userInfos, data) => this.exportMultiple(extension, userInfos, data))
    }

    exportConstruction = () => {
        this.props.onClick((data) => {
            let { columns = [], columnWidths = [] } = this.props
            let name
            try {
                name = this.props.name()    
            } catch (err) {
                name = 'constructions.xlsx'
            }
            

            let merge = []
            let totalWorkingHour = 0
            let header = columns.map((data, index) => {
                return data.title
            })
            let firstIndex = 4
            let lastIndex = data.length + 3
            let rows = []

            rows.push(['月別労務費'])
            rows.push(['全現場労務時間'])
            rows.push(header)
            data.map((item, yy) => {
                totalWorkingHour += item.construction_workTime
                let row = []
                row = columns.map(column => {
                    return this.getValue(item, column)
                })
                rows.push(row)
            })
            rows.push(['合計', ''])
            let wscols = this.getWidths(columns, columnWidths)

            const ws = XLSX.utils.aoa_to_sheet(rows);

            ws['!cols'] = wscols;
            ws['!merges'] = merge;
            ws[`C${lastIndex + 1}`] = { f: `SUM(C${firstIndex}:C${lastIndex})` };
            ws['B2'] = { f: `SUM(C${firstIndex}:C${lastIndex})` };
            ws[`D${lastIndex + 1}`] = { f: `ROUND(SUM(D${firstIndex}:D${lastIndex}), 0)` };

            for (let i = firstIndex; i <= lastIndex; i++) {
                ws[`D${i}`] = { f: `ROUND(($B$1 / $B$2) * C${i}, 2)` };
            }

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            XLSX.writeFile(wb, name + "." + 'xlsx', { type: "array", bookType: 'xlsx' })
        })
    }

    exportLabos = () => {
        this.props.onClick((data) => {
            this.export('csv', data)
        })
    }

    render() {
        const { type, onClick } = this.props
        return (
            <Tooltip title={I18n.t('toolTip.export')} key="export">
                <div style={{ display: "inline" }}>
                    {(() => {
                        if ( type === 'constructionExport') {
                            return (
                                <IconButton onClick={this.exportConstruction}>
                                    <Icon>cloud_download</Icon>
                                </IconButton>
                            )
                        } else if ( type === 'exportLabos') {
                            return (
                                <IconButton onClick={this.exportLabos}>
                                    <Icon>cloud_download</Icon>
                                </IconButton>
                            )
                        } else {
                            return (
                                <IconButton onClick={onClick ? this.exportMultileXlsx : (type === 'excel' ? this.exportXlsx : this.exportCSV)}>
                                    <Icon>cloud_download</Icon>
                                </IconButton>
                            )
                        }
                    })()}

                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem onClick={this.exportCSV}>CSV</MenuItem>
                        <MenuItem onClick={this.exportXlsx}>Excel</MenuItem>
                    </Menu>
                </div>
            </Tooltip>
        )
    }
}