import React from 'react';
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { IconButton, Icon, Tooltip, Button } from '@material-ui/core'
import BaseView from 'views/BaseView'
import PaperFade from 'components/Main/PaperFade'
import { I18n } from 'react-redux-i18n'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import _ from 'lodash'


const GridTable = React.lazy(() => import('components/Table/GridTable'))

const styles = theme => ({
    gridTable: {
        height: "calc(100vh - 100px)"
    },
    cssLink: {
        textDecoration: 'none'
    },
    button: {
        marginLeft: "5px",
    },
    input: {
        display: 'none',
    },
})

class Index extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
            statusConfirm: false, // biến check xác nhận cập nhật trạng thái
            deleteConfirm: false,
            copyData: null,
            statusWarning: false,
            status1: '',
            status2: '',
            type: '',
            manage1: null,
            manage2: null,

        }
        this.onCancel = this.onCancel.bind(this)
        this.hide = this.hide.bind(this)
        this.onClose = this.onClose.bind(this)
        this.warningCalendar = this.warningCalendar.bind(this)
        this.table = {
            columns: [
                {
                    name: 'index',
                    title: I18n.t("Table.header.user.index"),
                    type: "text",
                    filterable: false,
                    sortable: false
                },
                {
                    name: 'dateRequest',
                    title: I18n.t("Table.header.dateRequest"),
                    formatterComponent: (data) => {
                        let date = _.get(data.row, "insert.when", "")
                        return moment(date).format("YYYY-MM-DD")
                    },
                    filterFormat: "YYYY/MM/DD",
                    type: "date"
                },
                {
                    name: 'isoDate',
                    title: I18n.t("Table.header.date"),
                    formatterComponent: (data) => {
                        let date = _.get(data.row, "isoDate", "")
                        return moment(date).format("YYYY-MM-DD")
                    },
                    filterFormat: "YYYY/MM/DD",
                    type: "date"
                },
                {
                    name: 'User.name',
                    title: I18n.t('Table.header.user.name'),
                    formatterComponent: (data) => {
                        let userName = _.get(data.row, "User.name", "")
                        let userCode = _.get(data.row, "User.code", "")
                        return `${userName} (${userCode})`
                    }
                },
                {
                    name: 'type',
                    title: I18n.t('Table.header.report.type'),
                    formatterComponent: (data) => {
                        let type = _.get(data.row, "type", "")
                        return this.renderTypeRequest(type)
                    },
                    type: 'text'
                },
                {
                    name: 'content',
                    title: I18n.t('Table.header.content'),
                    filterable: false,
                    formatterComponent: (data) => {
                        let type = _.get(data.row, "type", "")
                        let engineeringNumber = _.get(data.row, "engineeringNumber", "")
                        let reason = _.get(data.row, "reason", "")
                        let alternativeScheduledDate = _.get(data.row, "alternativeScheduledDate", "")
                        if(parseInt(type) === 4) {
                            return `①工番: ${engineeringNumber ? engineeringNumber : ""}\n\n②理由: ${reason ? reason : ""}\n\n③代替予定日: ${alternativeScheduledDate ? alternativeScheduledDate : ""}\n`
                        } else {
                            return _.get(data.row, "content", "")
                        }
                    },
                    type: 'text'
                },
                {
                    name: 'detailRequest',
                    title: I18n.t('Button.detail'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        return this.customActionColumn(data.row)
                    }
                },
                {
                    name: 'allow',
                    title: I18n.t('Table.header.allowPrimary'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        let getReturn = this.renderStatus(data)
                        return getReturn || ''
                    }
                },
                {
                    name: 'action',
                    title: I18n.t('Table.header.action'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        let getReturn = this.renderAllowSkip(data)
                        return getReturn || ''
                    }
                },
            ],
            defaultSort: [{ columnName: "isoDate", direction: "desc" }],
            tableColumnExtensions: [
                { columnName: 'index', align: 'center' },
                { columnName: 'detailRequest', align: 'center' },
                { columnName: 'action', align: 'center' },
                { columnName: 'allow', align: 'center' },
                { columnName: 'content'},
                { columnName: 'User.name', wordWrapEnabled: true },
                { columnName: 'type', wordWrapEnabled: true },
            ],
            columnWidths: [
                {
                    name: 'index',
                    width: 90
                },
                {
                    name: 'dateRequest',
                    width: 100
                },
                {
                    name: 'isoDate',
                    width: 100
                },
                {
                    name: 'User.name',
                    width: 150
                },
                {
                    name: 'type',
                    width: 150
                },
                {
                    name: 'content',
                    width: 250
                },
                {
                    name: 'detailRequest',
                    width: 100
                },
                {
                    name: 'allow',
                    width: 90
                },
                {
                    name: 'action',
                    width: 250
                }
            ]
        }
        this.ConfirmDialog = null
        this.renderToolbarActions = this.renderToolbarActions.bind(this)
        this.renderSelectedActions = this.renderSelectedActions.bind(this)
        this.manage1Submit = this.manage1Submit.bind(this)
        this.manage2Submit = this.manage2Submit.bind(this)
        this.changeStatusAllow = this.changeStatusAllow.bind(this)
    }

    hide() {
        this.setState({ open: false })
    }
    onCancel() {
        this.hide()
    }
    onClose() {
        this.setState({ statusConfirm: false, deleteConfirm: false })
    }

    onHideSetCalendar = () => {
        let {status1, status2, copyData, manage1, manage2 } = this.state
        let userLogin = JSON.parse(localStorage.user)._id
        if (userLogin == manage1) {
            this.manage1Submit(copyData, status1)
        } else if (userLogin == manage2) {
            this.manage2Submit(copyData, status2)
        }
        this.setState({statusWarning: false})
    }

    changeStatus(value) {
        this.setState({ statusConfirm: true, copyData: value.data }) // statusConfirm thay đổi trạng thái xác nhận
        let manage1 = _.get(value.data, 'manage1', null)
        let manage2 = _.get(value.data, 'manage2', null)
        let userLogin = JSON.parse(localStorage.user)._id
        if (userLogin == manage1) {
            this.setState({ status1: value.status1, manage1: manage1, manage2: manage2 })
        } else if (userLogin == manage2) {
            this.setState({ status2: value.status2, manage1: manage1, manage2: manage2 })
        }
    }

    renderStatus(data) {
        let { classes } = this.props
        let status = _.get(data.row, 'status', null)

        switch (status){
            case false:
            case '':
                return <div>
                        {I18n.t("Input.request.status.request")}
                </div>
            case true:
                return  <div>
                        {I18n.t("Input.request.status.approve")}
                </div>
            default :
                return ''
        }
    }

    renderAllowSkip(data) { // Button Allow, Skip
        let permissions = JSON.parse(localStorage.user).permissions || []
        let cancel_approval_management = false
        for (let i of permissions) {
            if(i === 'cancel_approval_management') {
                cancel_approval_management = true
                break
            }
        }

        let { classes } = this.props
        let manage1 = _.get(data.row, 'manage1', null)
        let manage2 = _.get(data.row, 'manage2', null)
        let status1 = _.get(data.row, 'status1', "")
        let status2 = _.get(data.row, 'status2', "")
        let userId = JSON.parse(localStorage.user)._id
        let id = _.get(data.row, "_id", null)
        let getData = _.get(data, "row", null)
        if (userId == manage1) {
            switch (status1){
                case true:
                    return <div>
                        <Button color='primary' variant="contained">
                            {I18n.t("Button.allow")}
                        </Button>
                        <Button color='inherit' variant="contained" disabled={true} className={classes.button}>
                            {I18n.t("Button.skip")}
                        </Button>
                        <Button disabled={!cancel_approval_management} onClick={() => this.deleteStatusAllow({ status1: false, data: getData })} color='inherit' variant="contained" className={classes.button}>
                            {I18n.t("Button.cancel2")}
                        </Button>
                    </div>
                case false:
                    return  <div>
                        <Button color='inherit' variant="contained" disabled={true}>
                            {I18n.t("Button.allow")}
                        </Button>
                        <Button color='primary' variant="contained" className={classes.button}>
                            {I18n.t("Button.skip")}
                        </Button>
                        <Button disabled={!cancel_approval_management} onClick={() => this.deleteStatusAllow({ status1: false, data: getData })} color='inherit' variant="contained" className={classes.button}>
                            {I18n.t("Button.cancel2")}
                        </Button>
                    </div>
                case null:
                case '':
                    return  <div>
                        <Button onClick={() => this.changeStatusAllow({ status1: true, data: getData })} color={'inherit'} variant="contained">
                            {I18n.t("Button.allow")}
                        </Button>
                        <Button onClick={() => this.changeStatusAllow({ status1: false, data: getData })} color={'inherit'} variant="contained" className={classes.button} >
                            {I18n.t("Button.skip")}
                        </Button>
                        <Button disabled={true} onClick={() => this.deleteStatusAllow({ status1: false, data: getData })} color={'inherit'} variant="contained" className={classes.button} >
                            {I18n.t("Button.cancel2")}
                        </Button>
                    </div>
                default :
                    return ''
            }
        } else if (userId == manage2) {
            switch (status2){
                case true:
                    return <div>
                        <Button color='primary' variant="contained">
                            {I18n.t("Button.allow")}
                        </Button>
                        <Button color='inherit' variant="contained" disabled={true} className={classes.button}>
                            {I18n.t("Button.skip")}
                        </Button>
                        <Button disabled={!cancel_approval_management} onClick={() => this.deleteStatusAllow({ status2: false, data: getData })} color='inherit' variant="contained" className={classes.button}>
                            {I18n.t("Button.cancel2")}
                        </Button>
                    </div>
                case false:
                    return  <div>
                        <Button color='inherit' variant="contained" disabled={true}>
                            {I18n.t("Button.allow")}
                        </Button>
                        <Button color='primary' variant="contained" className={classes.button}>
                            {I18n.t("Button.skip")}
                        </Button>
                        <Button disabled={!cancel_approval_management} onClick={() => this.deleteStatusAllow({ status2: false, data: getData })} color='primary' variant="contained" className={classes.button}>
                            {I18n.t("Button.cancel2")}
                        </Button>
                    </div>
                case null:
                case '':
                    return <div>
                        <Button onClick={() => this.changeStatusAllow({ status2: true, data: getData })} color='inherit' variant="contained" >
                            {I18n.t("Button.allow")}
                        </Button>
                        <Button onClick={() => this.changeStatusAllow({ status2: false, data: getData })} color='inherit'  variant="contained" className={classes.button}>
                            {I18n.t("Button.skip")}
                        </Button>
                        <Button disabled={true} onClick={() => this.deleteStatusAllow({ status2: false, data: getData })} color='inherit'  variant="contained" className={classes.button}>
                            {I18n.t("Button.cancel2")}
                        </Button>
                    </div>
                default:
                    return
            }
        }
    }

    changeStatusAllow(value) { //  thay đổi trạng thái xác nhận
        this.setState({ statusConfirm: true, copyData: value.data }) 
        let manage1 = _.get(value.data, 'manage1', '')
        let manage2 = _.get(value.data, 'manage2', '')
        let userLogin = JSON.parse(localStorage.user)._id
        let type = _.get(value, 'data.type', '')
        if (userLogin == manage1) {
            this.setState({ status1: value.status1, manage1: manage1, type: type })
        } else if (userLogin == manage2) {
            this.setState({ status2: value.status2, manage2: manage2, type: type })
        }
    }

    deleteStatusAllow(value) {
        this.setState({ deleteConfirm: true, copyData: value.data })
    }

    dialogDeleteConfirm(deleteConfirm) { // Cập nhật trạng thái Delete Confirm
        return (
            <Dialog
                open={deleteConfirm}
                onClose={this.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    {I18n.t('Button.deleteConfirmAllow')}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => this.deleteConfirm()} color="primary">
                        {I18n.t('Button.ok')}
                    </Button>
                    <Button onClick={this.onClose} color="primary">
                        {I18n.t('Button.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    deleteConfirm() {
        this.props.onDeleteConfirmSubmit({ data: this.state.copyData, deleteConfirm: true})
        this.setState({ deleteConfirm: false })
    }

    dialogAllowSkip(statusConfirm) { // Cập nhật trạng thái Allow, Skip
        return (
            <Dialog
                open={statusConfirm}
                onClose={this.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    {I18n.t('Button.changeConfirmAllow')}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => this.warningCalendar()} color="primary">
                        {I18n.t('Button.ok')}
                    </Button>
                    <Button onClick={this.onClose} color="primary">
                        {I18n.t('Button.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    
    warningCalendar() { // Thêm cảnh báo lựa chọn lịch làm việc
        let { status1, status2, copyData, manage1, manage2, type } = this.state
        let userLogin = JSON.parse(localStorage.user)._id
        if(type == 6){
            if (userLogin == manage1) {
                if(status1 == false){
                    this.manage1Submit(copyData, status1)
                }
                this.setState({statusWarning: status1})
            }
            if (userLogin == manage2) {
                if(status2 == false){
                    this.manage2Submit(copyData, status2)
                }
                this.setState({statusWarning: status2})
            }
        } else {
            if (userLogin == manage1) {
                this.manage1Submit(copyData, status1)
            } else if (userLogin == manage2) {
                this.manage2Submit(copyData, status2)
            }
        }
        this.setState({ statusConfirm: false })
    }

    manage1Submit(copyData, status1){
        this.props.onSubmit({ data: copyData, status1: status1 })
    }
    manage2Submit(copyData, status2){
        this.props.onSubmit({ data: copyData, status2: status2 })
    }
    
    dialogSetCalendar(statusWarning) {
        return (
            <Dialog
                open={statusWarning}
                onClose={this.onHideSetCalendar}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    {I18n.t('Exception.setCalendar')}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => this.onHideSetCalendar()} color="primary" size='small' >
                        {I18n.t('Button.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }


    renderTypeRequest = (type) => {
        switch (type) {
            case 1:
                return I18n.t("Input.request.typeEarlyLate")
            case 2:
                return I18n.t("Input.request.typeOT")
            case 3:
                return I18n.t("Input.request.typeSalary")
                // return '有給申請'
            case 4:
                return I18n.t("Input.request.typeWorkOnDayOff")
            case 5:
                return I18n.t("Input.request.typeCompensated")
                // return '代休申請'
            case 6:
                return I18n.t("Input.request.typeReplace")
            case 7:
                return '月報申請'
            case 8:
                return I18n.t("Input.request.absent")
            case 9:
                return I18n.t("Input.request.dayoff")
            case 10:
                return I18n.t("Input.request.typeLate")
            default:
                return null
        }
    }
    customActionColumn(data) {
        return (
            <Button variant="contained" color='primary' onClick={() => this.setback(data)}>
                {I18n.t("Button.detail")}
            </Button>
        )
    }
    setback = (data) => {
        const { _id, type, userId, requestForReport} = data
        localStorage.setItem('back', 'requests')
        if (type === 7) {
            this.goto(`/monthly_reports?user_id=${userId}&year_month=${requestForReport}`)
        } else {
            this.goto(`/requests/${_id}`)
        }
    }
    renderToolbarActions() {
        return [

        ]
    }
    renderDialogConfirmDelete() {
        return (
            <ConfirmDialog
                ref={(ref) => this.ConfirmDialog = ref}
                title={I18n.t('Message.deleteDialogTitle')}
                content={I18n.t('Message.deleteDialogContent')}
                onSubmit={this.props.onDeleteData}
            />
        )
    }
    renderSelectedActions(selectedIds) {
        return [
            <Tooltip title={I18n.t("toolTip.delete")} key="create">
                <IconButton key="delete" onClick={() => this.ConfirmDialog.show(selectedIds)}>
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
        ]
    }

    render() {
        const { data, classes, users } = this.props
        const { statusConfirm, statusWarning, deleteConfirm } = this.state
        // open biến check hiện detailConstruction 
        // statusConfirm biến check xác nhận cập nhật trạng thái
        return (
            <PaperFade showLoading={true}>
                {
                    this.dialogAllowSkip(statusConfirm)
                }
                {
                    this.dialogDeleteConfirm(deleteConfirm)
                }
                {
                    this.dialogSetCalendar(statusWarning)
                }
                { data &&
                    <GridTable
                        id={`RequestIndex${moment().format("YYYYDDMM")}`}
                        className={classes.gridTable}
                        onFetchData={this.props.onFetchData}
                        onRefTable={this.props.onRefTable}
                        columns={this.table.columns}
                        rows={data.data}
                        totalCount={data.total}
                        pageSize={data.pageSize}
                        defaultSort={this.table.defaultSort}
                        showCheckboxColumn={false}
                        // isRequestPage={true}
                        height="auto"
                        selectedActions={this.renderSelectedActions}
                        tableActions={this.renderToolbarActions}
                        tableColumnExtensions={this.table.tableColumnExtensions}
                        defaultColumnWidths={this.table.columnWidths}
                    />
                }
                {this.renderDialogConfirmDelete()}
            </PaperFade>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(Index))
